* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body {
    background: #ffffff;// #fafafa;    
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased!important;
   // -moz-osx-font-smoothing: grayscale;
    color: #333;
}



img {
  user-select: none;
}

//#7159c1
/*

* {
    box-sizing: border-box;
  }
  
  input[type=text], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }
  
  label {
    padding: 12px 12px 12px 0;
    display: inline-block;
  }
  
  input[type=submit] {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
  }
  
  input[type=submit]:hover {
    background-color: #45a049;
  }
  
  .container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
  }
  
  .col-25 {
    float: left;
    width: 25%;
    margin-top: 6px;
  }
  
  .col-75 {
    float: left;
    width: 75%;
    margin-top: 6px;
  }
  
  // Clear floats after the columns 
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  // Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other 
  @media screen and (max-width: 600px) {
    .col-25, .col-75, input[type=submit] {
      width: 100%;
      margin-top: 0;
    }
  }

  */