.content-audit {    
  top: 0px;
  left: 0px;
  width: 100%;
  height: 90vh;    
  position: sticky;
}

.base-browser-audit {
  height:50%;    
}

.container-browser-audit {  
  width: 100%;
  height: 100%;    
}

#base-grid-audit {
  top: 0;        
  padding-left: 4px;
  padding-right: 4px;
  position: sticky;  
  width: 100%;        
  height: 36vh;
  overflow-y: auto;    
}

#content-grid-audit {       
  width: 100%;
  height: 100%;
  background: #f4f6f7; /* Old browsers */
  background: -moz-radial-gradient(center, ellipse cover,  #f4f6f7 0%, #f4f6f7 99%); /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover,  #f4f6f7 0%,#f4f6f7 99%); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center,  #f4f6f7 0%,#f4f6f7 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f6f7', endColorstr='#f4f6f7',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */       
}

#common-grid-audit {
  font-family: "Helvetica Neue", Helvetica,Arial,sans-serif;
  font-size: 12px;
  border-collapse: collapse;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 50px; 
 // border: solid 1px #f1f1f1;
  border-radius: 10px;  
  //table-layout: fixed;
  border-collapse: collapse; 
}

th {
  position: sticky;
  top: 0px;
  height: 30px;
  background-color: #fff;      
  vertical-align: middle;
}

.img-sort-audit {
  float: right;
  background-color: transparent;
  margin-right: 8px;
  user-select: none;
}

#col-grid-title-common-audit, 
#col-grid-title-checked-audit,
#col-grid-title-date-audit,
#col-grid-title-btn-audit { 
  color:#222222;
  font-weight: bold;
  //height: 28px;     
  font-family: inherit;
  font-size: 13;    
  vertical-align: middle;
  text-shadow: #f0c3bfad 0.0em 0.0em 0.1em;  
  height: 31px;
  cursor: pointer;  
}

#col-grid-title-cnpj-audit {
  height: 30px;  
}

#col-grid-data-cnpj-audit,
#col-grid-title-cnpj-audit {
  text-align: center;
  width: 140px;
  cursor: pointer;
}

#col-grid-title-common-audit,
#col-grid-data-common-audit {
  text-align: left;
  padding-left: 3px;
} 

#col-grid-data-common-audit, 
#col-grid-data-checked-audit, 
#col-grid-data-date-audit, 
#col-grid-btn-audit {
  height: 30px;
}

#col-grid-data-common-audit, 
#col-grid-data-date-audit {
  color: #222222;
  text-shadow: #f0c3bfad 0.0em 0.0em 0.1em;    
}

#col-grid-title-checked-audit,
#col-grid-data-checked-audit {
  text-align: center;
  width: 90px!important;    
}

#col-grid-title-date-audit,
#col-grid-data-date-audit {
  text-align: center;
  width: 60px!important;
}

#col-grid-title-btn-audit,
#col-grid-btn-audit {
  text-align: center;
  width: 76px;
}

.grid-buttons-audit {
  width: 100%;    
}

#columnSelector-audit {
  float: right;
  width: 1px;
  height: 15px;
  cursor: col-resize;
  background-color: #ddd;
  user-select: none;
}

#row-grid-data-audit:nth-child(even) {
  background-color:#fff9f9;
}

#row-grid-data-audit:hover {
  background-color:#f5f5f5;
  border-top: solid 1px #f1f1f1;
  border-bottom: solid 1px #f1f1f1;
}

#row-grid-title-audit {    
  background:#ffffff; // Old browsers #674e9e
  border-bottom: 1px solid #f1f1f1;
}

#col-grid-filter-audit {    
  position: sticky;
  top: 30px;
  background-color:#fff;
}

#col-grid-filter-audit div {    
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 4px;    
}

.grid-buttons-audit {
  top: 0px;
  height: 30px;
  width: 100%;
  background: transparent;
  align-items: center;
}

.grid-buttons-audit button {
  background:transparent;
  height: 31px;
  width: 31px;
  border-radius: 2px;
  cursor: pointer;  
}    

#input-grid-filter-audit {
  height: 30px;
  width: 100% !important;    
  border: 1px solid #ccc;
  border-radius: 4px;
  border-color: #f2f2f2;  
  background-color: #f9f9f9;
  //color:#00000009;
  box-sizing: border-box;
  transition: width 0.4s ease-in-out;
  font-size: 13px;
  padding-left: 4px;
  text-shadow: #f0c3bfad 0.0em 0.0em 0.0em;     
}

#input-grid-filter-audit:focus {
  border: 1.3px solid lightblue;
  background-color:  #eff4f5;
}

#check-grid-filter-audit {
  border: solid 1px #f1f1f1;
}

#input-grid-filter-audit::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #00000009;
  font-size: 12px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
}

#input-grid-filter-audit::-moz-placeholder { /* Firefox 19+ */
  color: #00000009;
  font-size: 12px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
}

#input-grid-filter-audit:-ms-input-placeholder { /* IE 10+ */
  color: #00000009;
  font-size: 12px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
}

#input-grid-filter-audit:-moz-placeholder { /* Firefox 18- */
  color:#00000009;
  font-size: 12px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
}

#grid-buttons-audit .buttons-audit button:hover{
  background:#f5f5f5;
}

#grid-buttons-audit button img {
//  opacity: 0.75;
-moz-transform: scale(0.87);
-webkit-transform: scale(0.87);
  transform: scale(0.87);    
}

#grid-buttons-audit button img:hover {
-moz-transform: scale(1.0);
-webkit-transform: scale(1.0);
  transform: scale(1.0);
}

.base-detail-audit {
  top: 0;        
  padding-left: 4px;
  padding-right: 4px;
  position: sticky;  
  width: 100%;        
  height: 50%;
  overflow-y: auto;  
}

#row-grid-title-detail-audit {    
  background:#ffffff; // Old browsers #674e9e
  border-bottom: 1px solid #f1f1f1;  
}

#col-grid-title-common-detail-audit {
  color:#222222;
  font-weight: bold;
  //height: 28px;     
  font-family: inherit;
  font-size: 13;    
  vertical-align: middle;
  text-shadow: #f0c3bfad 0.0em 0.0em 0.1em;  
  height: 30px;  
  cursor: pointer;  
  padding-left: 2px;

  position: sticky;
  top: 30px;
  width: auto;
  background-color: #fff;      
}
